import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "_content" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  class: "_input",
  name: "product",
  id: "product"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "_product" }
const _hoisted_6 = { class: "component" }
const _hoisted_7 = ["onClick"]

import { Component, getComponents } from "@/api/api_usage";
import EndpointListModal, {
  Row,
} from "@/components/api_usage/EndpointListModal.vue";
import Icon from "@/components/Icon.vue";
import InputBox from "@/components/InputBox.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import TableSection from "@/components/TableSection.vue";
import { onBeforeMount, ref } from "vue";
import { PRODUCTS } from "@/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'MonthlyUsageTab',
  setup(__props) {

const test = (e) => {
  console.log(e);
};

const isEndpointListModalOpen = ref<boolean>(false);
const components = ref<Component[]>([]);
const totalComponents = ref<number>(0);
const selectedComponents = ref<Row[]>([]);

const onSelectComponent = (component: Component) => {
  isEndpointListModalOpen.value = true;
  selectedComponents.value = component.endpoints;
};

onBeforeMount(async () => {
  const { data, totalData } = await getComponents();
  components.value = data;
  totalComponents.value = totalData;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("select", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("option", {
            value: "",
            selected: "",
            disabled: ""
          }, "All products", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PRODUCTS), (product) => {
            return (_openBlock(), _createElementBlock("option", {
              key: product,
              value: product
            }, _toDisplayString(product[0].toUpperCase() + product.slice(1)), 9, _hoisted_4))
          }), 128))
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("button", { class: "_btn" }, [
          _createElementVNode("i", { class: "fa-solid fa-arrows-rotate" }),
          _createElementVNode("p", null, "Refresh")
        ], -1))
      ]),
      _createElementVNode("div", null, [
        _createVNode(InputBox, { "left-icon": "magnifying-glass" })
      ])
    ]),
    _createVNode(TableSection, {
      "total-data": totalComponents.value,
      onChangeSize: test,
      onNext: test,
      onPrev: test,
      onPage: test
    }, {
      error: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "_error" }, [
          _createElementVNode("img", {
            src: "https://cdn.mekari.design/illustration/blank-slate/NotResultFound_PB_M_01.png",
            alt: "no-data"
          }),
          _createElementVNode("h1", null, "No component found"),
          _createElementVNode("p", null, "Recheck the keywords you have typed and try searching again.")
        ], -1)
      ])),
      header: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("th", null, "Product", -1),
        _createElementVNode("th", null, "Component name", -1),
        _createElementVNode("th", null, "Used", -1),
        _createElementVNode("th", null, "Used (%)", -1),
        _createElementVNode("th", null, "Quota", -1),
        _createElementVNode("th", null, null, -1)
      ])),
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(components.value, (row, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("div", null, [
                _createVNode(Icon, {
                  product: row.product,
                  size: "1.5rem"
                }, null, 8, ["product"])
              ]),
              _createElementVNode("p", null, " Mekari " + _toDisplayString(row.product[0].toUpperCase() + row.product.slice(1)), 1)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(row.name), 1),
                _createElementVNode("a", {
                  onClick: ($event: any) => (onSelectComponent(row)),
                  class: _normalizeClass(row.endpoints.length > 1 ? '_link' : '_non-link')
                }, _toDisplayString(row.endpoints.length > 1
                    ? "View endpoint URL"
                    : row.endpoints[0].url), 11, _hoisted_7)
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("p", null, _toDisplayString(row.endpoints[0].usedQuota), 1)
            ]),
            _createElementVNode("td", null, [
              _createVNode(ProgressBar, {
                progress: 
                (row.endpoints[0].usedQuota / row.endpoints[0].quota) * 100
              
              }, null, 8, ["progress"])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("p", null, _toDisplayString(row.endpoints[0].quota), 1)
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("td", null, [
              _createElementVNode("button", { class: "_btn _btn-white" }, "Add Quota")
            ], -1))
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["total-data"]),
    _createVNode(EndpointListModal, {
      "is-modal-open": isEndpointListModalOpen.value,
      data: selectedComponents.value,
      "total-data": selectedComponents.value.length,
      onSearch: test,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (isEndpointListModalOpen.value = false))
    }, null, 8, ["is-modal-open", "data", "total-data"])
  ]))
}
}

})